.name {
  font-weight: 800 !important;
  margin-bottom: 0.25rem;
}

.email {
  margin-bottom: 0 !important;
  color: var(--am-sec-text-color-light) !important;
}

.headertext {
  font-weight: 600 !important;
}

.subtext {
  color: var(--am-sec-text-color-light) !important;
}
