.thumbnailCard {
  background-color: var(--am-bg-color);
  border-style: solid;
  border-radius: 20px;
  border-width: 3px !important;
  border-color: var(--am-primary-color-light) !important;
  cursor: pointer;
}

.previewCard {
  background-color: var(--am-bg-color);
  border-radius: 20px;
  box-shadow: 0 0 25px rgb(0 0 0 / 15%);
  position: sticky;
  top: 40px;
}
