@charset "UTF-8";
/*!
 * Academia Match
 * Global Stylesheet
!*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/*!
 * Theme Styling Variables
!*/

:root {
  /* Theme Colors */
  --am-navy-dark: #070d59;
  --am-blue: #3366ff;
  --am-blue-light: #dbeaf7;
  --am-white: #ffffff;

  --am-primary-color: var(--am-blue);
  --am-primary-color-light: var(--am-blue-light);
  --am-primary-color-hover: #2952cc;
  --am-secondary-color: var(--am-navy-dark);
  --am-alt-color: var(--am-white);

  /* Fonts */
  --am-primary-font: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  /* Text Style */
  --am-pri-text-color: var(--am-navy-dark);
  --am-sec-text-color: var(--am-navy-dark);
  --am-sec-text-color-light: rgba(7, 13, 89, 0.5);

  /* Other */
  --am-bg-color: var(--am-white);
}
[data-theme="dark"] {
  /* To be implemented later */
  --am-navy-dark: #000000;
}

/*!
 * Global Styling
!*/

body {
  margin: 0 !important;
  font-family: var(--am-primary-font) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background-color: var(--am-bg-color) !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}

nav {
  z-index: 19 !important;
}

h1 {
  color: var(--am-pri-text-color);
  letter-spacing: -1px;
  font-weight: 700;
}

h2 {
  color: var(--am-pri-text-color);
  font-size: 36px !important;
  font-weight: 700 !important;
  letter-spacing: -1px !important;
}

h3 {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: var(--am-pri-text-color) !important;
}

h4 {
  /* 
  font-size: 20px !important;
  font-weight: 700 !important; */
  color: var(--am-sec-text-color) !important;
}

h5 {
  color: var(--am-sec-text-color);
  font-size: 16px !important;
  font-weight: 400 !important;
}

p {
  color: var(--am-sec-text-color) !important;
  font-size: 12px !important;
}

.color-light {
  color: var(--am-sec-text-color-light) !important;
}

ul {
  color: var(--am-sec-text-color) !important;
  font-size: 12px !important;
}

/* Buttons */

a {
  color: var(--am-primary-color);
  text-decoration: none;
}
a:hover {
  color: var(--am-primary-color-hover) !important;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

/* Common Layout */

.h-vh-100 {
  height: 100vh;
}

.margin-t-large {
  margin-top: 120px;
}

.margin-b-large {
  margin-bottom: 120px;
}

.padding-t-large {
  padding-top: 120px;
}

.padding-b-large {
  padding-bottom: 120px;
}
