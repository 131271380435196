.formInput {
  width: 200px;
}

.checkBox {
  margin-top: 1px;
}

label {
  margin-right: 1px;
}

.required:before {
  content: "*";
  font-weight: bold;
}

.button {
  background-color: #013db3;
  width: 30%;
  color: white;
  border-radius: 20px !important;
  margin-left: 35% !important;
  margin-bottom: 2.5% !important;
  outline: none !important;
  outline-offset: none !important;
}
