.optionalFormInput {
  width: 400px;
  margin-bottom: 0%;
  margin-top: 1%;
}

.requiredFormInput {
  width: 400px;
  margin-bottom: 0%;
  margin-top: 1%;
}

.requiredFormInput:after {
  content: " *";
  color: red;
  width: 400px;
}

.attributeButton {
  margin: 5px !important;
  width: 75px;
}

.attributeItem {
  list-style-type: none;
  display: inline;
}

.attributeWrapper {
  display: inline;
  padding: 0px 0px;
}

/* radio cards */

.cardDiv {
  border-radius: 10px;
  border: 1px solid var(--am-secondary-color);
}

.cardText {
  color: auto;
}

.cardDiv:hover {
  cursor: pointer;
}

.inputElement {
  display: none;
}

.inputElement:checked + .cardDiv {
  background-color: var(--am-primary-color);
  color: var(--am-alt-color) !important;
  border-width: 0;
}

.inputElement:checked + div .cardText {
  font-weight: 700 !important;
  color: var(--am-alt-color) !important;
}
