.footer {
  background-color: #013db3;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.title {
  color: #fff;
}

.description {
  color: #fff;
}

.footerlink,
.footerlink:hover {
  color: #fff;
}

.smallText {
  color: var(--am-sec-text-color-light) !important;
  font-size: 12px !important;
  margin-right: 1em;
}
